@media (max-width: 450px){
    .w-30-mobile{
        width: 30% !important;
    }
}
.w-50{
    width: 50%;
}
.w-15{
    width: 15%;
}
.mt-1{
    margin-top: 1%;
}
.a-menu{
    color: #000;
    text-decoration: none;
}
.a-footer{
    color: #fff;
    text-decoration: none;
}
.font-menu{
    font-family:'Cormorant Garamond', serif;
    font-size: 20px;
    font-weight: 500;
}
.font-menu:hover{
    text-decoration: underline;
}
.font-footer{
    font-family:'Cormorant Garamond', serif;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}
.font-footer-links{
    font-family:'Cormorant Garamond', serif;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}
.font-footer-links:hover{
    text-decoration: underline;
}
.bg-grey{
    background-color: gray;
}
.color-white{
    color: #fff;
}
.color-black-manu-phone{
    color: #000 !important;
}