@media (max-width: 450px){
    .font-title-contact{
        font-size: 40px !important;
    }
    .font-contact-info{
        font-size: 16px !important;
    }
    .w-90-mobile{
        width: 90%;
    }
}
.mt-3{
    margin-top: 3%;
}
.font-title-contact{
    font-family: 'Cinzel', serif;
    font-size: 50px;
    color: #000;
}
.bg-grey-contact{
    background-color: #cfcfcf;
    padding-top: 10%;
}
.font-contact-info{
    font-family: 'Cinzel', serif;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5%;
    padding-top: 5%;
}
.font-contact-info:hover{
    text-decoration: underline;
}
.font-followUs{
    font-family: 'Cinzel', serif;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5%;
    padding-top: 5%;
}
.a-links{
    color: #000;
    text-decoration: none;
}