
/*measure */

@media (max-width: 450px){
    .p-description{
      margin: 5% !important;
    }
    .position-font-banner{
        top: 15% !important;
        left: 22% !important;
    }
    .position-font-banner-name{
        top: 20% !important;
        left: 30% !important;
    }
    .font-banner{
        font-size: 28px !important;
    }
    .font-banner-name{
        font-size: 15px !important;
    }
    .w-90-mobile{
        width: 90%;
    }
}
.w-92{
    width: 92%;
}
.font-banner{
    font-family: 'Cinzel', serif;
    font-size: 60px;
    color: #fff;    
}
.font-banner-name{
    font-family: 'Sen', sans-serif;
    color: #fff;
    font-size: 25px;
}
.position-relative{
    position: relative;
}
.position-font-banner{
    position: absolute !important;
    top: 50%;
    left: 35%;
}
.position-font-banner-name{
    position: absolute !important;
    top: 60%;
    left: 45%;
}
.title-aboutUs{
    font-family: 'Cormorant Garamond', serif;
    font-size: 40px;
    line-height: 35px;
}
.p-description{
    font-family: 'Cormorant Garamond', serif;
    margin: 4% 12% 5% 25%;
    text-align: initial;
    font-size: 20px;
    line-height: 35px;
}