
@media (max-width: 450px){
   .btn-sm-kitchen-mobile{
    border: 1px solid #fff !important;
    max-width: 28% !important;
    height: 5% !important;
    color: #fff !important;
    position: absolute !important;
    top: 45% !important;
    left: 38% !important;
    font-size: 10px !important;
   }
   .w-90-mobile{
       width: 90% !important;
   }
   .btn-sm-bath{
    border: 1px solid #fff !important;
    max-width: 28% !important;
    height: 100% !important;
    color: #fff !important;
    font-size: 10px !important;
}
.font-lr-mobile{
    font-family: 'Cinzel', serif;
    font-size: 30px !important;
    color: #fff !important;
}
.font-kitchen{
    font-family: 'Cinzel', serif;
    font-size: 40px !important;
    color: #fff !important;
 }
 .mt-40{
    margin-top: 40%;
 }
 .text-decoration-none{
     text-decoration: none;
 }
}
.h-stack{
    height: 400px;
}
.img-background-bath{
    background-image: url('../../assets/portfolio/bathMobile.jpg');
    background-repeat: no-repeat;
}
.img-background-kitchen{
    background-image: url('../../assets/portfolio/kitchenMobile.jpg');
    background-repeat: no-repeat;
}

.m-1{
    margin: 1%;
}
.w-90{
    width: 90%;
}
.w-55{
    width: 55%;
}
.font-title{
    font-family: 'Cinzel', serif;
    font-size: 60px;
    color: #000;
}
.btn-sm-kitchen{
    border: 1px solid #fff !important;
    width: 10% !important;
    height: 5% !important;
    color: #fff !important;
    position: absolute !important;
    top: 65% !important;
    left: 25% !important;
}
.btn-sm-livingRoom{
    border: 1px solid #fff !important;
    width: 10% !important;
    height: 5% !important;
    color: #fff !important;
    position: absolute !important;
    top: 65% !important;
    left: 65% !important;
}
.font-kitchen{
    font-family: 'Cinzel', serif;
    font-size: 40px;
    color: #fff;
    position: absolute !important;
    top: 55% !important;
    left: 24% !important;
}
.font-lr{
    font-family: 'Cinzel', serif;
    font-size: 40px;
    color: #fff;
    position: absolute !important;
    top: 55% !important;
    left: 62% !important;
}

.img-change:hover{
    transition: all 0.5s ease-out;
    filter: brightness(50%) !important;
}
